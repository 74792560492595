.donateButtons{
	width: 20%;	
	font-size: calc(14px + 6 * ((100vw - 720px) / 720));
	font-weight: 800;
	color: rgb(255, 52, 62);
	border-width: 0
}

@media(max-width: 600px){
	.donateButtons{
		width: 22%;
	}
}

.donateButtons:hover {
	color: white;
	background-color: rgb(255, 52, 62);
}

.donate-separator-left{
	display: flex;
	flex-direction:column; 
	justify-content:center;
	color:  white;
	font-size: calc(20px + 10 * ((100vw - 720px) / 720));
}