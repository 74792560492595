.full-page-banner-wrapper{
	overflow: auto;
	font-size: calc(16px + 10 * ((100vw - 500px) / 500));
	position: fixed;
	width: 325px;
	height: 100vh;
	background: linear-gradient(147deg, #0b476f 0%, #041825 100%);
	z-index: 1;
	color: white;
    left: 50%;
    transform: translate(-50%, 0%);	
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;	
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}


.social-row-wrapper{
	align-content: center;
	margin-bottom: 1em;
}

.full-page-link{
    color: white;
}

.full-page-link:hover {
    color: white;
}

.white-link{
	color: white;
}

.white-link:hover {
	color: white;
}
