.meet-jack-left {
  margin: 4rem 5rem 3rem 5rem;
}

.meet-jack-left-text{
  font-size: calc(15px + 20 * ((100vw - 720px) / 720));
  font-weight: 800;
  text-align: left;
  color: #1D2D5C;  
  margin-bottom: 0.5em;
}

.meet-jack-text {
  font-size: calc(14px + 4 * ((100vw - 720px) / 720));
  text-align: left;
  border-width: 5px;
  border-color: #1D2D5C;
  border: solid;
  padding: 3em 3em 3em 3em;
  margin: 0rem 5rem 5rem 5rem;
}

.meet-jack-right {
  align-self: center;
}

@media (max-width: 460px) {
  .meet-jack-text {
    font-size: calc(14px + 4 * ((100vw - 720px) / 720));
    text-align: left;
    border: 0;
    padding: 4em 4em 4em 4em;
    margin: 0 0 0 0 ;    
  }
  .meet-jack-left{
    margin: 4rem 4rem 0rem 4rem;    
  }
}

@media (max-width: 720px) {
  .meet-jack-left-text {
    text-align: center;
    font-size: 14px;
  }
}

@media (max-width: 1200px) {
  .meet-jack-right {
    display: none !important;
  },
  .meet-jack-left-text {
    text-align: center;
  }  
}

@media (min-width: 1200px) {
  .meet-jack-mobile-img {
    display: none !important;
    height: 0px;
  }
}