.footer-text{
	color: white;
}

.footer-text:hover {
	color: white;
}

.footer-left {
	text-align:left;
	padding: 0em 0em 5em 5em	
}

.footer-logo{
	max-height: 50px;
}


@media(min-width: 992px){
	.footer-left {
		padding: 0em 0em 5em 10em	
	}
} 

@media(max-width: 400px){
	.footer-left {
		padding: 0em 0em 5em 3em	
	}
	.footer-logo {
		width: 90%;
		float: right;
		margin-right: 1em;
	}
	.footer-email {
		font-size: 10px;
	}
	.footer-email-row{
		justify-content: right;
		margin-right: 1em;
	}		
} 

.footer-email-row{
	margin-top: 1em;
}		


