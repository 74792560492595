.navbar-wrapper-cross{
  background-color: #1D2D5C
}

.navbar-wrapper{
  background-color: white
}


.navbar{
  width: 100%;
  min-height: 6vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5em 0 5em;
}

.scrolled{
  position: fixed;
  top: 0;
  left: 0;
  background-color: lightblue;
}
.logo img{
  width: 50px;
}

.navbar-text{
  color: #1D2D5C;
  font-size: 1.25em;
}

.navbar-text:hover {
  color: #1D2D5C;
}

.navbar-text-wrapper{
  width: 15%;
  padding-top: 2em;
  padding-bottom: 2em;
}

.navbar-text-mobile {
  color: white;
  font-weight: 800;
  font-size: 1.6em;
  width: 100%;
}

.navbar-text-mobile-wrapper {
  text-align: center;
  margin-bottom: 2em;
}

.navbar-text-mobile:hover {
  color: white;
}

.navbar-right-icon{
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
}

.navbar-donate-btn{
  width: auto; 
  font-size: calc(14px + 6 * ((100vw - 720px) / 720));
  font-weight: 800;
  color: white;
  border-width: 0;
  background-color: rgb(255, 52, 62);
}

.navbar-donate-btn:hover {
  color: white;
  background-color: rgb(255, 52, 62);
}



