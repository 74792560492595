.unsubMessage{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.unsubCategory{
    font-weight: 700;
}

.unsubBtn{
    margin-top: 30px;
}

.subManagerLink{
    margin-top: 10px;
    font-size: small;
    color:#1890ff;
}

.subManagerLink:hover{
    cursor: pointer;
    
}

.email-preferences {
    width: 80%;
    margin-top: 88px;
}

.unsubBtnContainer {
    transform: translate(-50%, -50%);
    position:absolute;
    left: 50%;
}


.unsubCheckmark {
    position: relative;
    left: 70px;
    top: 3px;
}

.unsubSpecific {
    margin-bottom: 50%;
}