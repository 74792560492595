.info-collector-row1{
    font-size:  calc(40px + 20* ((100vw - 720px) / 720));
    color: white;
    font-weight: 800;
    margin-bottom: 1em;
}

.info-collector-row{
    background-image: linear-gradient(147deg, #0b476f 0%, #041825 100%);
}

.info-collector-right {
    padding: 5em; 
    display:flex; 
    flex-direction:column;
    justify-content:center;
}

.info-collector-img {
    width: 100%;
}

@media (max-width: 1295px){
    .info-collector-img{
    width: calc(100% + (1295px - 100vw));
}
}

@media (max-width: 992px){
    .info-collector-img{
    display: none;
}
}

.info-collector-donate-text{
    align-self:center;
    color:white; 
    font-weight:800; 
    font-size:1.5em;
}

@media(max-width: 660px){
    .info-collector-donate-text{
        width: 100%;
        padding-bottom: 1em;
    }
    .info-collector-right{
        padding: 3em;
    }
}

@media(max-width: 385px){
    .info-collector-donate-text{
        width: 100%;
        padding-bottom: 1em;
    }
    .info-collector-right{
        padding: 1em;
    }
}