.App {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
}

.fixedPopup {
  color: rgb(29, 45, 92);
  position: fixed;
  z-index: 1;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
  background-color: white;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.closeButton{
    border-color: rgb(29, 45, 92);
    cursor: pointer;
    color: rgb(29, 45, 92);
    border-style: solid;
    border-width: 1px;
    padding: 0 25px 0 25px;
}

