.volunteer-column-wrapper {
	padding:3em 5em 2em 5em;
}

.volunteer-input{
	margin: 0.5em 0em 0.5em 0em;
	height: auto;
	font-size: 1.5em;
	border: 0;
}

.volunteer-input::placeholder {
	color: #1D2D5C;
}

.volunteer-row1{
	color: white;
	font-weight: 800;
	font-size: calc(20px + 40 * ((100vw - 720px) / 720));
	text-align: left;
}

.volunteer-row2{
	color: white;
	font-weight: 800;
	font-size: calc(20px + 10 * ((100vw - 720px) / 720));	
	margin-bottom: 1em;
	text-align: left;
}

.join-team-button{
    font-weight:800;
    font-size: calc(20px + 5 * ((100vw - 720px) / 720));
    border: 0;
    height: auto;
	color:white; 
	background-color:rgb(255, 52, 62); 
	width: 100%;
	margin-top: 0.5em;
}

.join-team-button:hover{
	color:rgb(255,52,62) ;
}

.volunteer-img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.volunteer-img-wrapper {
	place-content:center; 
	display:flex; 
	overflow:hidden;
	alignItems:center; 
	flexDirection:column; 
	justifyContent:center;
	max-height: 130vh;
}

@media (max-width: 991px){
	.volunteer-row1{
		font-size: calc(50px + 50 * ((100vw - 720px) / 720));
	}
    .volunteer-img-wrapper {
	    display: none;
	}
}

@media (max-width: 1550px){
    .volunteer-img{
    width: calc(100% + (1550px - 90vw));
}
}

@media (max-width: 575px){
	.volunteer-column-wrapper {
		padding: 2em 3em 2em 3em;
	}
}