.checkout-donate-buttons{
	color: #1D2D5C;
	background-color: #d1d5f3;
	border-color:#1D2D5C;
	border: 0;
	font-size: 1.2vw;
	width: 20%;
	font-weight: 800;
	font-size: calc(14px + 4 * ((100vw - 720px) / 720));
}

.checkout-donate-buttons:hover {
	color: #1D2D5C;
	background-color: #bac1f1;
}

.checkout-img {
    width: 100%;
    object-fit: cover;
    object-position: center;
}

@media (max-width: 1550px){
    .checkout-img{
    width: calc(100% + (1550px - 90vw));
}
}

.checkout-img-wrapper {
	place-content:center; 
	display:flex; 
	overflow:hidden;
	alignItems:center; 
	flexDirection:column; 
	justifyContent:center;
	max-height: 130vh;
}

.checkout-left {
	justify-content: center;
	padding: 2em
}

@media(min-width: 992px){
	.checkout-left {
		padding: calc(2em + (100vw - 992px)/20);
	}
}

@media(min-width: 1200px){
	.checkout-left {
		padding: 5em;
	}
}