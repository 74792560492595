.landing-first-row-left{
    background-image: linear-gradient(147deg, #0b476f 0%, #041825 100%);
    padding:5em;
    color: white;
}

.email-signup-button{
    font-size:"1em"; 
    font-weight:800;
    background-color: rgb(169 44 42);
    color: white;
    border-width: 0;
    white-space: pre-wrap;
}

.email-signup-button:hover {
    background-color: white;
    color: rgb(169 44 42);
}

.landing-elect-jack{
    font-size:  calc(40px + 20 * ((100vw - 720px) / 720));
    font-weight:800;
    padding-bottom: 0.5em;
}

.vision-button{
    font-weight:800;
    font-size: calc(15px + 5 * ((100vw - 720px) / 720));
    background-color: white;
    color: #1D2D5C;
    border-width: 2;
    border-color: #1D2D5C;
    height: auto;
}

.vision-button:hover {
    font-weight:800;
    font-size: calc(15px + 5 * ((100vw - 720px) / 720));
    background-color: white;
    color: #1D2D5C;
    border-width: 2;
    border-color: #1D2D5C;
    height: auto;
}

.vision-button::after {
    content: "";
    background: url("arrow-blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-left: 7px;
    position: relative;
    transition: all 0.8s ease-in-out;
    transform: translateY(17%);
}

.vision-button:hover::after {
    content: "";
    background: url("arrow-blue.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-left: 7px;
    position: relative;
    transition: all 0.8s ease-in-out;
    transform: translate(17%, 17%);
}

.blue-text-800{
    font-weight: 800;
    color: #1D2D5C;
}

/*//general styling for antd inputs*/
.inputs{
    font-size: calc(15px + 5 * ((100vw - 720px) / 720));
    color: #1D2D5C;
}

.inputs::placeholder{
    color: #1D2D5C;
}

.landing-cover-wrapper{
    place-content:center; 
    display:flex; 
    overflow: hidden;
    alignItems:center; 
    flexDirection:column; 
    justifyContent:center;
    max-height: 130vh;
}

.landing-cover{
    width:100%;
    object-fit: cover;
    object-position: center;
}

@media(max-width:  992px) {
    .landing-cover{
    width: calc(100% + (992px - 100vw));
    transform: translate(calc( (992px - 100vw) / 5 ));
}
}

.landing-better-toronto-img-wrapper{
    place-content:center; 
    display:flex; 
    overflow: hidden;
    alignItems:center; 
    flexDirection:column; 
    justifyContent:center;
    max-height: 130vh;
}

.landing-better-toronto-img{
    width:100%;
    object-fit: cover;
    object-position: center;    
}

@media(max-width:  767px) {
    .landing-elect-jack{
        font-size:2.8em;
    }
    .vision-button{
        font-size: 1.5em;
    }
    .vision-button:hover {
        font-size: 1.5em;
    }    
    .landing-better-toronto-img-wrapper{
        display: none;
    }
    .landing-first-row-left {
        padding:2em;
    }
}
