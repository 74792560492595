.fade-in {
    transition: opacity 0.5s ease;
}

.fade-out {
    opacity: 0;
    transition: opacity 2s ease;
}

.issues-big-card {
    text-align: -webkit-center;
    text-align: -moz-center;
    border-radius: 6px;
    height: 100%;
    color: white;
    background: linear-gradient(121deg, rgba(27,69,138,1) 34%, rgba(11,49,112,1) 88%)
}

.issues-big-card-inner-wrapper {
    width:90%; 
    padding-top:2rem;
}

.big-card-line1{
font-size: calc(14px + 4 * ((100vw - 720px) / 720));
}

.big-card-line2{
font-size: calc(22px + 10 * ((100vw - 375px) / 1065));
font-weight: 800;
padding-top: 0.3em;
padding-bottom: 0.5em;
text-align: left;
}

.big-card-line3{
text-align: left;
padding-right: 15%;
font-size: calc(18px + 4 * ((100vw - 1065px) / 1065));
}

.issues-small-card {
    border-radius: 6px;
    height: 200px;
    color: #1D2D5C;
    background-color: #e0e5ef
}

.issues-small-card-mobile {
    border-radius: 6px;
    height: 200px;
    color: #1D2D5C;
    background-color: #e0e5ef;
    margin: 0em 5em 20px 5em;    
}

@media(max-width: 992px){
    .issues-small-card-mobile{
        margin: 0em 1em 20px 1em;
    }
}

.small-card-line1{
color: #1D2D5C;
font-size: 18px;
padding-bottom: 1em;
width: 100%;
}

.small-card-line2{
    color: #1D2D5C;
font-size: calc(20px + 2 * ((100vw - 375px) / 1065));
font-weight: 800;
width: 100%;
}

.issues-small-card-inner-wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    place-content: center;
}

.issues-row-wrapper{
    height: 420px;
    margin: 0em 5em 20px 5em;
    max-width: 1400px;
}

@media(max-width: 992px){
    .issues-row-wrapper{
        margin: 0em 1em 20px 1em;
    }
}

.issues-read-more-btn{
    font-weight:800;
    font-size: calc(15px + 5 * ((100vw - 720px) / 720));
    background-color: transparent;
    color: white ;
    border-width: 2;
    border-color: white;
    height: auto;
    padding: 1rem 2rem 1rem 2rem;
    border-radius: 30px;
}

.issues-read-more-btn:hover {
    font-weight:800;
    font-size: calc(15px + 5 * ((100vw - 720px) / 720));
    background-color: transparent;
    color: white ;
    border-width: 2;
    border-color: white;
    height: auto;
}

.issues-read-more-btn::after {
    content: "";
    background: url("arrow-white.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-left: 7px;
    position: relative;
    transition: all 0.8s ease-in-out;
    transform: translateY(17%);
}

.issues-read-more-btn:hover::after {
    content: "";
    background: url("arrow-white.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 13px;
    height: 13px;
    display: inline-block;
    margin-left: 7px;
    position: relative;
    transition: all 0.8s ease-in-out;
    transform: translate(17%, 17%);
}

.issues-article-body{
     font-size:  calc(16px + 4 * ((100vw - 375px) / 1065));  
     width: 70%;
     max-width: 700px; 
     text-align: left;
     color: #1D2D5C;
}

.issues-caption{
     font-size:  calc(12px + 4 * ((100vw - 375px) / 1065));  
}

@media(max-width: 720px){
    .issues-article-body{
     width: 90%;
}
}



.default-link{
  text-decoration: none;
}

.article-link{
    color: #1D2D5C;
    text-decoration: underline;
}

.article-link:hover {
    color: #3e5cb4;
    text-decoration: underline;
}

.issues-share-btn {
    font-weight:800;
    font-size: calc(15px + 5 * ((100vw - 720px) / 720));
    background-color: transparent;
    border-width: 2;
    border-color: #1D2D5C;
    height: auto;
    padding: 1rem 2rem 1rem 2rem;
    margin: 0rem 2rem 2rem 2rem;
    border-radius: 30px;    
    color: #1D2D5C;
}

.issues-share-btn:hover {
    color: #3e5cb4;
    border-color: #3e5cb4;
}