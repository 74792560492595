.thank-you-header{
	text-align: center;
	font-size: calc(20px + (100vw / 100));
	padding-left: calc(100vw / 10);
	padding-right: calc(100vw / 10);
	padding-top: 3em;
	padding-bottom: 3em
}

@media(min-width: 992px){
	.thank-you-header{
	padding-left: 5em;
	padding-right: 5em;
	}
}

@media(max-width: 420px){
	.thank-you-header{
		font-size: calc(20px + (100vw / 100));
		padding-left: 0em;
		padding-right: 0em;
	}
}

